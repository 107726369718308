<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light"
          :show="dismissCountDown"
          dismissible
          variant="success"
          fade
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
        </b-alert>
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <!-- skeleton  -->
            <form class="needs-validation" @submit.prevent="formRec">
              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <!-- <div class="col-sm-6 col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="branchId">สาขา:</label>

                          <multiselect
                            disabled
                            id="branchId"
                            v-model="form.branchId"
                            :options="localData"
                            class=""
                            label="nameTh"
                            :show-labels="false"
                            track-by="nameTh"
                            @input="handleSearch"
                          ></multiselect>
                        </div>
                      </div> -->
                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="vin">เลขที่เอกสาร:</label>

                        <b-form-input
                          disabled
                          id="vin"
                          v-model="form.regJobCode"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="reqDate"> วันที่:</label>
                        <date-picker
                          disabled
                          v-model="form.rcvdDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                          :class="{
                            'is-invalid': submitform && $v.form.rcvdDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.form.rcvdDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.rcvdDate.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="mb-3 position-relative">
                            <label for="regCode">
                              เลือกจากเลขที่ใบแจ้งจำหน่าย:</label
                            >

                            <b-form-input
                              disabled
                              id="vin"
                              v-model="form.regCode"
                              type="text"
                              class="form-control"
                              value="Otto"
                            ></b-form-input>
                            <!-- disabled="disabled"  -->
                          </div>
                        </div>
                        <!-- <div class="col-md-3 col-sm-3 col-3">
                          <div class="mt-2 position-relative">
                            <b-button
                              :disabled="this.form.status !== 'DFT'"
                              class="btn"
                              variant="info"
                              v-b-modal.modalReg
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-sm-12 col-md-12">
                          <div class="mb-3 position-relative">
                            <label for="customerNameTh">ชื่อลูกค้า:</label>
                            <b-form-input
                              disabled
                              id="vin"
                              v-model="form.customerFullNameTh"
                              type="text"
                              class="form-control"
                              value="Otto"
                            ></b-form-input>
                          </div>
                        </div>
                        <!-- <div class="col-md-3 col-sm-3 col-3">
                          <div class="mt-2 position-relative">
                            <b-button
                              class="btn"
                              variant="info"
                              v-b-modal.modalReg
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-12 col-md-12 col-sm-12">
                          <div class="mb-3 position-relative">
                            <label for="vehicleId">ทะเบียนรถ:</label>
                            <b-form-input
                              disabled
                              id="vin"
                              v-model="form.vehicleId"
                              type="text"
                              class="form-control"
                              value="Otto"
                            ></b-form-input>
                          </div>
                        </div>
                        <!-- <div class="col-md-3 col-sm-3 col-3">
                          <div class="mt-2 position-relative">
                            <b-button
                              :disabled="this.form.status !== 'DFT'"
                              class="btn"
                              variant="info"
                              v-b-modal.modalVe
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="col-6 col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="vin">เลขตัวถัง:</label>

                        <b-form-input
                          disabled
                          id="vin"
                          v-model="form.vin"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="col-6 col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="modelNameTh">รุ่น:</label>

                        <b-form-input
                          disabled
                          id="modelNameTh"
                          v-model="form.modelNameTh"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-12 col-md-12 col-sm-12">
                          <div class="mb-3 position-relative">
                            <label for="paymentId">เลขที่ใบเสร็จรับเงิน:</label>
                            <b-form-input
                              disabled
                              id="vin"
                              v-model="form.paymentId"
                              type="text"
                              class="form-control"
                              value="Otto"
                            ></b-form-input>
                          </div>
                        </div>
                        <!-- <div class="col-md-3 col-sm-3 col-3">
                          <div class="mt-2 position-relative">
                            <b-button
                              :disabled="this.form.status !== 'DFT'"
                              class="btn"
                              variant="info"
                              v-b-modal.modalPay
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-4">
                      <div class="mb-2">
                        <label class="form-label d-block mb-3"></label>
                        <b-form-radio-group
                          disabled
                          v-model="form.isSale"
                          :options="optionSale"
                          name="radios-stacked"
                          stacked
                          :class="{
                            'is-invalid': submitform && $v.form.isSale.$error,
                          }"
                        >
                        </b-form-radio-group>
                        <div
                          v-if="submitform && $v.form.isSale.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.isSale.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-5">
                      <div class="mb-3 position-relative">
                        <label for="note">หมายเหตุ:</label>
                        <!-- disabled="disabled"  -->
                        <textarea
                          disabled
                          v-model="form.note"
                          class="form-control"
                          name="textarea"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <br />
                  <!-- <div class="col-md">
                    <button
                      :disabled="this.form.status !== 'BRCUS'"
                      class="btn btn-success float-end"
                      type="submit"
                    >
                      บันทึก
                    </button>
                  </div> -->
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title-desc">รายการเอกสาร</p>
              </div>
              <!-- <div class="col-6">
                <button class="btn btn-primary float-end">อัพโหลดเอกสาร</button>
              </div> -->
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">ลำดับ</th>
                    <th scope="col">ประเภทเอกสาร</th>
                    <th scope="col">รูปภาพ/ไฟล์</th>
                    <th scope="col">รับเอกสาร</th>
                    <th scope="col">วันที่</th>
                    <th scope="col">ผู้รับ</th>
                    <!-- <th scope="col"></th> -->
                  </tr>
                </thead>

                <tbody v-for="(item, index) in document" :key="index">
                  <tr>
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.docTypeNameTh }}</td>
                    <td>
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <b-button
                              @click="showModal(item)"
                              size="sm"
                              variant="primary"
                              >แนบเอกสาร</b-button
                            >
                          </div>
                          <div class="col-md-6">
                            <ul class="list-inline mb-0">
                              <li class="list-inline-item">
                                <a
                                  v-if="item.fileId != null"
                                  class="px-2 text-info"
                                  v-b-tooltip.hover
                                  @click="getDataloadImage(item)"
                                >
                                  <i class="uil-paperclip font-size-18"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="row">
                        <div class="col-sm-12 col-md-12 align-items-center">
                          <div class="row align-items-center">
                            <img
                              :src="this.imagePath"
                              class="preview-img"
                             @change="getDataloadImage(item)"
                            />
                           
                          </div>
                        </div>
                      </div> -->
                    </td>
                    <td>
                      <div class="form-check form-switch">
                        <input
                          v-model="item.isReceived"
                          :disabled="item.isReceived !== null"
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          @change="putCheck(item)"
                        />
                      </div>
                    </td>

                    <td>{{ item.receivedDate }}</td>

                    <td>{{ item.receivedBy }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <div class="row mb-4 mt-4">
              <div class="col text-end ms-1">
                 <span><code>*** กรุณาระบุรับเอกสารให้ครบก่อนยืนยัน</code></span
                ><br />
                <button class="btn btn-danger ms-1" @click="alertSubmit()">
                  ยืนยันการรับเอกสาร
                </button>
                <!-- <button
                  v-if="document.length !== 3"
                  :disabled="this.form.status !== 'DFT'"
                  class="btn btn-danger ms-1"
                  @click="alertDoc()"
                >
                  ยืนยันการรับเอกสารเอกสาร
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <b-modal
      ref="modalUploadImg"
      :id="`modalUploadImg-${docId}`"
      :title="`อัพโหลดเอกสาร - ${this.docTypeNameTh}`"
      hide-footer
      size="l"
      centered
    >
      <!-- <span>ประเภทเอกสาร : {{ this.docTypeNameTh }}</span> -->

      <div class="card-body">
        <div class="row">
          <div class="col-md-9 col-sm-12 col-9">
            <div class="position-relative">
              <b-form-file
                type="file"
                ref="img"
                id="img"
                v-model="formDoc.image"
                accept="image/*"
                class="form-control"
                plain
                @input="uplondImg(docId)"
              ></b-form-file>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="modalImg"
      id="modalImg"
      title="ภาพประกอบ"
      hide-footer
      size="l"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <img
                :src="this.imagePath"
                class="preview-img"
                @click="singleImage = true"
              />
              <vue-easy-lightbox
                :visible="singleImage"
                :imgs="this.imagePath"
                @hide="singleImage = false"
              ></vue-easy-lightbox>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end col -->
  </Layout>
</template>
<script>
import // required,
//   maxValue,
//   minValue,
//   maxLength,
//   numeric,
"vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import DatePicker from "vue2-datepicker";
// import documentComponent from "@/components/documentComponent";

// import btnComponent from "@/components/btnComponent.vue";
// import ChangeDate from "@/components/changeDateComponent";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    // documentComponent,
    // Multiselect,
    DatePicker,
    // ChangeDate,
    // btnComponent,
  },
  page: {
    title: appConfig.receiveDocReg,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      apiShow: "api/reg-rcvd-msg/show",
      apiImg: "api/reg-rcvd-msg/full-size-image",
      apiCheck: "api/reg-rcvd-msg/received-doc",
      isReceived: "ได้รับเอกสาร",
      receivedBy: "ผู้รับ",
      overlayFlag: false,
      dismissSecs: 3,
      resalert: "",
      dismissCountDown: 0,
      loading: undefined,
      message: "โปรดตรวจสอบข้อมูล",
      title: "สร้างการรับเอกสาร",
      items: [
        {
          text: "งานทะเบียน",
          active: true,
        },
        {
          text: "รับเอกสารจากผู้เดินทะเบียน",
          href: "/receive-docReg",
        },
        {
          text: "สร้างการรับเอกสาร",
          active: true,
        },
      ],
      rowPerson: [],
      rowDocSend: [],
      optionCus: [],
      localData: [],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      optionsYear: [],
      filter: {
        startDate: "",
        endDate: "",
        branchId: "",
        licensePlate: "",
        sendCode: "",
        rcvdCode: "",
        docTypeId: "",
      },
      fields: [
        {
          key: "index",
          label: "เลือก",
        },
        {
          key: "regCode",
          sortable: true,
          label: "เลขที่ใบแจ้งจำหน่าย",
        },
        {
          key: "sendCode",
          sortable: true,
          label: "เลขที่นำส่ง",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "docTypeNameTh",
          sortable: true,
          label: "ประเภทเอกสาร",
        },
        {
          key: "sendDate",
          sortable: true,
          label: "วันที่ส่งเอกสาร",
        },

        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "status",
          label: "สถานะ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      optionSale: [
        { value: "1", text: "รถใหม่" },
        { value: "0", text: "รถต่อทะเบียน" },
      ],
      formDoc: {
        select: null,
        image: null,
        isReceived: null,
      },
      docTypeNameTh: "",
      docId: "",
      regJobId: this.$route.params.regJobId,
      provinces: [],
      amphures: [],
      districts: [],
      branchId: "",
      partId: "",
      roId: "",
      cusId: "",
      vehicleId: "",
      note: "",
      plsDate: "",
      price: "",
      cause: "",
      customerDecision: "",
      deposit: "",
      appointmentDate: "",
      customerName: "",
      customerMolbileNumber: "",
      psNsArriveDate: "",
      psStockAmount: "",
      singleImage: false,
      imagePath: "",
      branchIdSearch: "",
      branchIdRo: "",
      rowRo: [],
      page: "",
      totalPage: "",
      perPage: 10,
      currentPage: 1,
      rowpage: "",
      totalRecord: "",
      rowTest: [],
      totalRows: 1,
      rowParts: [],
      pageOptions: [5, 10, 25, 50, 100],
      selectMode: "single",
      selected: [],
      isLoading: "",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      optionsGroupId: [],
      optionsUnits: [],
      options: [],
      use: [
        { text: "ใช้กับศูนย์ซ่อมสีและตัวถัง", value: "1" },
        { text: "ใช้กับศูนย์บริการ", value: "2" },
      ],
      opcodeType: [
        {
          name: "อะไหล่นอก",
          id: "EX",
        },
        {
          name: "กำหนดเอง",
          id: "CUSTOM",
        },
      ],
      document: [],
      rowDocType: [],
      optionDoc: [],
      optionInvoice: [],
      isDisabled: false,
      rowsVehi: [],
      rowsCus: [],
      optionTypeDoc: [],
      form: {
        docId: [],
        branchId: "",
        regJobCode: "",
        rcvdDate: "",
        regCode: "",
        customerFullNameTh: "",
        vehicleId: "",
        vin: "",
        modelNameTh: "",
        paymentId: "",
        isSale: "",
        note: "",
      },
      searchform: {
        doc: "",
      },
      provinceId: "",
      amphurId: "",
      districtId: "",
      fullAddress: "",
      submitform: false,
      submit: false,
    };
  },

  validations: {},
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.branchIdSearch = { nameTh: item.nameTh, branchId: item.branchId };
      }
    });
    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
    // this.filter.branch = {branchId: user.branchId}
    // //log(user.branchId);
    this.getDataShow();
    // this.getPerson();
    this.getNow();
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.form.rcvdDate = date;
    },
    customLabelCus({ nameTh, familyNameTh }) {
      return `${nameTh != null ? nameTh : ""}  ${
        familyNameTh != null ? familyNameTh : " "
      }`;
    },
    onRowSelected(rowRo) {
      this.selected = rowRo[0];
      var roCode = { roCode: this.selected.roCode, roId: this.selected.roId };
      var customerFullNameTh = {
        nameTh: `${
          this.selected.customerNameTh != null
            ? this.selected.customerNameTh
            : ""
        } ${
          this.selected.customerFamilyNameTh != null
            ? this.selected.customerFamilyNameTh
            : ""
        }`,
        cusId: this.selected.cusId != null ? this.selected.cusId : "",
      };
      var vehicleId = this.selected.vehicleId;
      var customerMolbileNumber = this.selected.mobilePhone;
      // var branchName = { nameTh: this.selected.branchName };
      this.form.roId = roCode;
      this.form.customerFullNameTh = customerFullNameTh;
      this.form.vehicleId = vehicleId;

      this.form.customerMolbileNumber = customerMolbileNumber;
      // this.form.branchId = branchName;
      this.$refs["modalRo"].hide();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;
      this.getDocSend();
    },
    getPerson: function (event) {
      // this.overlayFlag = true;
      this.loadingPer = true; //skeleton true
      useNetw
        .get("api/reg-rcvd-msg/dealer-person", {
          params: {
            page: this.currentPage,
            perPage: this.perPage, //เฉพาะค้นหา สูงสุด 100
            name: event,
            // familyName: this.filterPer.familyName,
          },
        })
        .then((response) => {
          //(response);
          this.rowPerson = response.data.data;
          const perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / perPage);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loadingPer = false; //skeleton false
        });
    },
    getDataShow: function () {
      this.overlayFlag = true;
      useNetw
        .get(this.apiShow, {
          params: {
            regJobId: atob(this.regJobId),
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          this.form.sendDate = response.data.data.regJobDate;
          this.form.branchId = {
            nameTh: response.data.data.branchName,
            branchId: response.data.data.branchId,
          };

          this.form.note = response.data.data.note;
          this.form.status = response.data.data.status;
          this.form.doc = response.data.data.docCode;
          this.form.isSale = response.data.data.isSale;
          this.form.regJobCode = response.data.data.regJobCode;

          this.form.customerFullNameTh = `${
            response.data.data.customerNameTh
          } ${
            response.data.data.customerFamilyNameTh != null
              ? response.data.data.customerFamilyNameTh
              : ""
          }`;
          this.form.vehicleId = response.data.data.licensePlate;

          this.form.vin = response.data.data.vin;
          this.form.modelNameTh = response.data.data.modelNameTh;
          this.form.paymentId = response.data.data.paymentCode;
          this.form.regCode = response.data.data.regCode;

          this.document = response.data.data.document;
          // this.getDocCus();
          // this.getDocSend();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message + "<br>" + err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    showModal(data) {
      this.docId = data.docId;
      this.docTypeNameTh = data.docTypeNameTh;
      this.$refs["modalUploadImg"].show(this.docId);
    },
    getDocSend: function () {
      this.loading = true;
      // this.branchIdRo = branchId;
      useNetw
        .get("api/reg-rcvd-msg/dealer-reg-send-msg", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]":
              this.branchIdSearch !== null
                ? this.branchIdSearch.branchId
                : this.branchId,
            startDate: this.filter.startDate,
            sendCode: this.filter.sendCode,
            endDate: this.filter.endDate,
            regCode: this.filter.regCode,
            licensePlate: this.filter.licensePlate,
            docTypeId:
              this.filter.docTypeId != null
                ? this.filter.docTypeId.docTypeId
                : this.filter.docTypeId,
          },
        })
        .then((response) => {
          //log(response);
          this.rowDocSend = response.data.data;

          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataloadImage: function (data) {
      //  console.log(data);
      // this.regJobId = data.regJobId;
      this.fileId = data.fileId;
      // this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get(this.apiImg, {
          params: {
            regJobId: atob(this.regJobId),
            fileId: this.fileId,
          },
        })
        .then((response) => {
          //(this.imagePath);
          this.rowImg = response.data.data;
          this.imagePath = response.data.data.filePath;
          this.$refs["modalImg"].show();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getDocType: function () {
      this.loading = true;
      // this.branchIdRo = branchId;
      useNetw
        .get("api/reg-rcvd-msg/dealer-document-type", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.rowDocType = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.putSubmit();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    putSubmit() {
      useNetw
        .put("api/reg-rcvd-msg/submit", {
          regJobId: atob(this.regJobId),
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.$router.push({ name: "receiveDocReg" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    uplondImg(docId) {
      const bodyFormData = new FormData();

      // let file = new File([this.form.imagePath.path], "download.png", {
      //   type: "image/png",
      //   size: 144,
      // });
      const imagefile = document.querySelector("#img");
      // const image = document.querySelector(this.form.image);
      bodyFormData.append("docId", docId);
      bodyFormData.append(
        "image",
        imagefile.files[0] ? imagefile.files[0] : ""
      );

      useNetw
        .post("api/reg-rcvd-msg/upload", bodyFormData)

        .then((response) => {
          this.getDataShow();
          this.$refs["modalUploadImg"].hide();
          this.showAlert(response.data.message);
          // this.$router.push({ name: "receiveDocCus" });
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    putCheck(docId) {
      useNetw
        .put(this.apiCheck, {
          docId: docId.docId,
        })

        .then((response) => {
          this.getDataShow();
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$router.push({ name: "purchase-order" });
          // this.overlayFlag = false;
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    formRec() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.form.$invalid != true) {
        this.postSubmit();
      }
    },
    postSubmit() {
      useNetw
        .post("api/reg-rcvd-msg/store", {
          docId: this.form.docId,
          rcvdDate: this.form.rcvdDate,
          note: this.form.note,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "receiveDocReg" });
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    handleSearch() {
      if (this.branchIdSearch === "") {
        this.branchId;
      } else {
        this.branchId = this.branchIdSearch.branchId;
      }
      this.getPerson();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getPerson();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getPerson();
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped>
.preview-img {
  max-width: 200px;
}
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>
